(function () {
	'use strict';

	angular
		.module('event')
		.factory('repeating', ['seedcodeCalendar', repeating]);

	function repeating(seedcodeCalendar) {
		var dailyRule = function (rule) {
			rule.freq = RRule.DAILY;

			return rule;
		};

		var weeklyRule = function (rule) {
			rule.freq = RRule.WEEKLY;
			rule.byweekday = getSelected('weekly_byweekday');

			return rule;
		};

		var monthlyRule = function (rule) {
			rule.freq = RRule.MONTHLY;
			rule.byweekday = getSelected('monthly_byweekday');
			rule.byweekno = getSelected('byweekno');
			rule.bymonthday = getSelected('bymonthday');

			return rule;
		};

		var yearlyRule = function (rule) {
			rule.freq = RRule.YEARLY;
			rule.bymonth = getSelected('bymonth');

			return rule;
		};

		var addStop = function (scope, rule) {
			rule.until = scope.until;
			rule.count = scope.count;

			return rule;
		};

		var getSelected = function (classname) {
			var arr = [];
			var selected = angular.element('.' + classname + '.selected');
			//console.log(selected);
			for (var i = 0; i < selected.length; i++) {
				//console.log(selected[i].value);
				arr.push(selected[i].value);
			}
			return arr;
		};

		return {
			buildRule: function (scope) {
				var rule = {interval: scope.interval};

				if (angular.element('#daily').hasClass('active')) {
					rule = dailyRule(rule);
				} else if (angular.element('#weekly').hasClass('active')) {
					rule = weeklyRule(rule);
				} else if (angular.element('#monthly').hasClass('active')) {
					rule = monthlyRule(rule);
				} else if (angular.element('#yearly').hasClass('active')) {
					rule = yearlyRule(rule);
				}

				rule = addStop(scope);
				return rule;
			},
		};
	}
})();
